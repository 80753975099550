import Vue from "vue"
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import "@fortawesome/fontawesome-free/css/all.css"
import "flag-icons/sass/flag-icons.scss"
import createApp from "@zhoujianhui/vip-core"
import createIcon from "@zhoujianhui/vip-icon"
import vipIam from "@zhoujianhui/vip-iam"
import vipPreference from "@zhoujianhui/vip-preference"
import noticeManager from "@zhoujianhui/vip-notice-element"
import {errorAppender} from "@zhoujianhui/vip-error"
import {sessionErrorHandler} from "@zhoujianhui/vip-iam-session"
import App from "./App"
import {packageManager,loginErrorHandler,otherErrorHandler} from "@vip/global-trade-analysis-ui-module-package"
import gtaPackage from "@vip/global-trade-analysis-ui-module-package"

// 完整引入ElementUI
Vue.use(ElementUI, {
    size: "small"
})

// 引入IAM相关的指令
Vue.use(vipIam)

// 注册平台图标组件并配置SVG文件上下文
const icon = createIcon({
    svgContexts: [
        require.context("@zhoujianhui", true, /\.svg$/),
        require.context("@", true, /\.svg$/)
    ]
})
Vue.use(icon)

//引入个性化组件
Vue.use(vipPreference)

//引入表格自适应指令
Vue.use(gtaPackage)

Vue.config.productionTip = false;

(async () => {

    const app = await createApp(App, [
        errorAppender,
        noticeManager, // 通知管理器插件
        sessionErrorHandler, // 会话异常处理器
        loginErrorHandler, // 登录异常处理器
        otherErrorHandler, //异常处理器
        packageManager //套餐数据初始化
        ]
    )

    if (app) {
        app.$mount("#app")
    }

})()
