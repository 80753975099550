<template>
  <div class="content">
    <el-dialog :visible="true"
               class="bill-of-lading-pop-up-box-content" width="30%"  @close="handleCancel">
      <div class="neon-limits-of-authority">
        <div class="neon-limits-of-authority-img">
          <img src="../images/permissionDenied.png"/>
        </div>
        <div class="neon-limits-of-authority-content">没有权限访问</div>
        <div class="neon-limits-of-authority-content-particular">当前您的账号套餐已失效,您可以点击
          <a @click="skipPayPackage" style="cursor: pointer">购买套餐</a>
          获取更多权益
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "PackageExpire",
  methods:{
    skipPayPackage() {
      this.handleCancel()
      this.$router.push({
        path: '/user-center/product-package',
        query: {}
      })
    },
    handleCancel(){
      this.$emit("close",true);
    },
  }
}
</script>

<style lang="scss" scoped>
.content{
  ::v-deep .el-dialog__wrapper{
    z-index: 99999 !important;
  }
}
.bill-of-lading-pop-up-box-content{
  ::v-deep .el-dialog{
    //width: 29% !important;
    position: relative;
    //top:264px;
    top: 18%;
    left: 5%;
  }
  ::v-deep .el-dialog__header{
    padding: 0;
  }

}

.neon-limits-of-authority{
  width: 300px;
  height: 200px;
  text-align: center;
  margin:0 auto;
  padding: 20px;

  .neon-limits-of-authority-img{
    text-align: center;
  }
  .neon-limits-of-authority-content{
    font-size: 16px;
    font-weight: 900;
    margin: 10px 0;
    display: flex;
    justify-content: center;
  }
  .neon-limits-of-authority-content-particular{
    line-height: 26px;
  }

}
.without-permission-content {
  text-align: center;
  padding-top: 20px;

  span {
    font-size: 16px;
    font-weight: bold;
  }

  .contact {
    color: #1c75fd;
  }
}

.without-permission-content-img {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;

  img {
    width: 150px;
    height: 100px;
    margin-left: 20px;
  }
}
.powerful-function{
  background: #1c75fd;
  width: 61px;
  text-align: center;
  padding: 5px 5px;
  border-radius: 4px;
  position: relative;
  left: 152px;
  top: -16px;
  span{
    color: white;
  }
}
.wider-scope{
  background: #1c75fd;
  width: 61px;
  text-align: center;
  padding: 5px 5px;
  border-radius: 4px;
  position: relative;
  left: 380px;
  top: -39px;
  span{
    color: white;
  }
}
.handle-safe{
  background: #1c75fd;
  width: 61px;
  text-align: center;
  padding: 5px 5px;
  border-radius: 4px;
  position: relative;
  left: 611px;
  top: -65px;
  span{
    color: white;
  }
}
@media(min-width: 1300px) and (max-width: 1700px){
  .powerful-function{
    left: 94px;
  }
  .wider-scope{
    left: 263px;
  }
  .handle-safe {
    left: 426px;
  }
}

</style>