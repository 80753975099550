<template>
  <div class="app-main">
    <InsufficientPermission v-if="showInsufficientPermissionFlag"
                            :type="insufficientPermission.type"
                            :issue-type="insufficientPermission.issueType"
                            :issue-category="insufficientPermission.issueCategory"
                            @close="showInsufficientPermissionFlag=false"/>
    <PackageExpire v-else-if="showPackageExpireFlag"
                   @close="showPackageExpireFlag=false"/>
    <transition mode="out-in" name="fade-transform">
      <keep-alive :include="cachedRoutes" :max="keepAliveMaxNum">
        <router-view :key="key" class="app-main-height"/>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {eventBus} from '@zhoujianhui/vip-core'
import InsufficientPermission from "../../customs-data/component/InsufficientPermission"
import PackageExpire from "../../customs-data/component/PackageExpire";
import noReadMessageService from "../service/no-read-message";

export default {
  name: "AppMain",
  components:{
    PackageExpire,
    InsufficientPermission
  },
  data() {
    return {
      showInsufficientPermissionFlag:false,
      showPackageExpireFlag:false,
      insufficientPermission:{
        type: 1,
        issueType:"",
        issueCategory:"",
      },
      keepAliveMaxNum: 100
    }
  },
  created() {
    eventBus.$on("isPackagePermissionResource",this.showPackagePermission)
  },
  methods:{
    async showPackagePermission(arg){
      let result = await noReadMessageService.checkPackageIsExpire()
      if (result.isSuccess()){
        if (!result.data){
          this.showPackageExpireFlag = true
          return;
        }
      }
      this.insufficientPermission.type = arg.type ? arg.type : 1
      this.insufficientPermission.issueType = arg.issueType
      this.insufficientPermission.issueCategory = arg.category
      this.showInsufficientPermissionFlag = true
    }
  },
  computed: {
    ...mapGetters({
      visitedRoutes: "gta/visitedRoutes"
    }),
    cachedRoutes() {
      return this.visitedRoutes.filter(route => !route.meta.noKeepAlive).map(route => route.name);
    },
    key() {
      return this.$route.path;
    }
  }
}
</script>

<style lang="scss" scoped>
.app-main {
  //padding: $base-padding;
  box-sizing: border-box;
  min-height: $base-app-main-height;
  background-color: #f1f6fd;
  margin-top: $base-nav-bar-height;
  position: relative;
}
</style>
