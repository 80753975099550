import axios from "axios"
import {Result, URLSearchParamsBuilder} from "@zhoujianhui/vip-core";

/** * Copyright (C) 1990 2021 南京擎天科技集团
 * no-read-message.js
 * 创建人:luChao
 * 日期:2022-11-07 10.02
 * 描述:
 * 历史:
 */
const noReadMessageService = {
    baseUrl: "/user-notice",
    async getNoReadMessage(pageable,query) {
        try {
            const response = await axios.get(this.baseUrl ,{
                params: new URLSearchParamsBuilder()
                    .appendObject(query)
                    .appendPageable(pageable)
                    .build()
            })
            return Result.success("查询成功！").data(response.data).build();
        } catch (e) {
            return Result.error("查询异常！").build();
        }
    },

    async checkPackageIsExpire() {
        try {
            const response = await axios.get('/membership/check-package-expire')
            return Result.success("查询成功！").data(response.data).build();
        } catch (e) {
            return Result.error("查询异常！").build();
        }
    },

}
export default noReadMessageService