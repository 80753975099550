<template>
  <el-row class="portal-footer" type="flex" justify="center">
    <el-row class="portal-min-width">
      <el-row class="portal-footer-container" type="flex" justify="space-between">
        <el-row class="portal-footer-container-left" type="flex">
          <el-row class="portal-footer-container-left-title" type="flex">
            <img src="../images/logo.png" alt="" />
            <span class="portal-footer-container-left-title-split"></span>
            <span class="portal-footer-container-left-title-intro">您的全球专属生意管家</span>
          </el-row>
          <div class="portal-footer-container-left-footer">
            <el-row>咨询热线：025-962166</el-row>
            <el-row>地址：南京市江北新区天浦路26号</el-row>
          </div>
        </el-row>
        <el-row class="portal-footer-container-split"></el-row>
        <el-row class="portal-footer-container-product">
          <el-row class="portal-footer-container-tag" type="flex">
            <span class="blue-tag"></span>
            <span class="tag-title">产品方案</span>
          </el-row>
          <el-row class="portal-footer-container-tag-cont">
            <div class="portal-footer-container-tag-cont-margin-top">
              <span class="left-text hover-active" @click="handleTagClick( { path: '/portal',componentName: 'AnalysisReport',tabIndex: 3 } )">市场趋势分析</span>
              <span class="hover-active" @click="handleTagClick( { path: '/portal',componentName: 'BusinessRiskMonitoring',tabIndex: 2 } )">买家风险监控</span>
            </div>
            <div class="portal-footer-container-tag-cont-margin-top">
              <span class="left-text hover-active" @click="handleTagClick( { path: '/portal',componentName: 'GlobalTransactionMining',tabIndex: 0 } )">大数据精准获客</span>
              <span class="hover-active" @click="handleTagClick( { path: '/portal',componentName: 'GlobalTransactionMining',tabIndex: 0 } )">同行竞争监测</span>
            </div>
            <span class="portal-footer-container-tag-cont-margin-top hover-active" @click="handleTagClick( { path: '/portal',componentName: 'BusinessOpportunityIntelligent',tabIndex: 1 } )">商机智能推荐</span>
          </el-row>
        </el-row>
        <el-row class="partner">
          <el-row class="portal-footer-container-tag" type="flex">
            <span class="blue-tag"></span>
            <span class="tag-title">合作伙伴</span>
          </el-row>
          <el-row class="portal-footer-container-tag-cont">
            <div class="portal-footer-container-tag-cont-margin-top hover-active" @click="handleTagClick( { blank: 'https://www.zumoo.com/' } )">擎天助贸圈</div>
            <div class="portal-footer-container-tag-cont-margin-top hover-active" @click="handleTagClick( { blank: 'https://www.chinackts.com/' } )">出口退税服务平台</div>
          </el-row>
        </el-row>
        <el-row class="about-us">
          <el-row class="portal-footer-container-tag" type="flex">
            <span class="blue-tag"></span>
            <span class="tag-title">关于我们</span>
          </el-row>
          <el-row class="portal-footer-container-tag-cont">
            <div class="portal-footer-container-tag-cont-margin-top hover-active" @click="handleTagClick( { path: '/about-us?position=companyprofile' } )">企业介绍</div>
            <div class="portal-footer-container-tag-cont-margin-top hover-active" @click="handleTagClick( { path: '/about-us?position=aboutus' } )">联系我们</div>
          </el-row>
        </el-row>
        <el-row class="code-area" type="flex">
          <el-row class="code-area-item" type="flex">
            <img src="../../portal/images/ios.png" alt=""/>
            <span>IOS应用下载</span>
          </el-row>
          <el-row class="code-area-item" type="flex">
            <img src="../../portal/images/android.png" alt=""/>
            <span>安卓应用下载</span>
          </el-row>
        </el-row>
      </el-row>
      <el-row class="portal-footer-text">
        <span @click="handleTagClick( { path: '/privacy-policy' } )">隐私政策</span>
        <span @click="handleTagClick( { path: '/legal-notice' } )">法律声明</span>
        <span>Copyright 南京擎天全税通信息科技有限公司 版权所有</span>
        <span @click="handleTagClick( { blank: 'https://beian.miit.gov.cn/#/Integrated/index' } )">苏ICP备2021004295号</span>
      </el-row>
    </el-row>
  </el-row>
</template>

<script>
export default {
  name: "PortalFooter",
  methods: {
    handleTagClick(tag) {
      if (tag.blank) { //新窗口打开三方网址
        window.open(tag.blank, '_blank')
      } else if (tag.href) { //页面替换新网址
        window.location.href = tag.href
      } else if (tag.path){ //新窗口打开页面
        if (this.$route.path === tag.path) {
          this.$emit('tab:change:scroll',tag.componentName);
        }else {
          this.$router.push({
            path: tag.path,
            query: {
              tabIndex: tag.tabIndex
            }
          });
        }
      } else {
        this.$message.info('敬请期待')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.portal-footer {
  background-color: rgb(54, 58, 64);
  padding: 68px 0 58px;
  box-sizing: border-box;

  .hover-active {
    cursor: pointer;

    &:hover {
      color: #1890ff;
    }
  }

  .portal-footer-container {
    min-width: 1200px;
    padding-bottom: 30px;
    border-bottom: rgba(255, 255, 255, .2) solid 1px;
    margin-bottom: 30px;

    .portal-footer-container-left {
      flex-direction: column;

      .portal-footer-container-left-title {


        .portal-footer-container-left-title-split {
          background-color: rgb(255, 255, 255);
          opacity: 0.302;
          width: 2px;
          height: 13px;
          margin: 0 8px;
          align-self: flex-end;
        }

        .portal-footer-container-left-title-intro {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.8);
          align-self: flex-end;
        }

      }

      .portal-footer-container-left-footer {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.8);
        line-height: 1.2;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        div:nth-child(2) {
          margin-top: 20px;
        }

      }

    }

    .portal-footer-container-split {
      background-color: rgb(255, 255, 255);
      opacity: 0.2;
      width: 1px;
      height: 129px;
      align-self: center;
    }

    .portal-footer-container-tag {
      align-items: center;

      .blue-tag {
        background-color: rgb(62, 117, 255);
        width: 4px;
        height: 8px;
      }

      .tag-title {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.8);
        font-weight: bold;
        margin-left: 8px;
      }

    }

    .portal-footer-container-tag-cont {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.502);
      line-height: 1.2;
      padding-left: 16px;

      .left-text {
        display: inline-block;
        width: 150px;
      }

      .portal-footer-container-tag-cont-margin-top {
        margin-top: 25px;
        display: block;
      }

    }

    .code-area {

      .code-area-item {
        flex-direction: column;
        align-items: center;

        &:nth-child(2) {
          margin-left: 16px;
        }

        img {
          width: 77px;
          height: 77px;
        }

        span {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.502);
          margin-top: 12px;
        }
      }
    }

  }

  .portal-footer-text {
    min-width: 1200px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.502);

    span {
      cursor: pointer;

      &:hover {
        color: #1890ff;
      }
    }

  }

  .portal-footer-text span:not(:first-child) {
    margin-left: 50px;
  }

}
</style>
