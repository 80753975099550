<template>
  <router-view/>
</template>

<script>
import "../style/portal-vab.scss"
import "../style/element-variables.scss"

export default {
  name: "PageLayout",
  beforeRouteLeave(to, from, next) {
    window.scrollTo(0,0)
    next()
  },
  mounted() {
    const html = document.querySelector('html');
    html.classList.add('witseye-portal');
  },
  beforeDestroy(){
    const html = document.querySelector('html');
    html.classList.remove('witseye-portal');
  }
}
</script>
