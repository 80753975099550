<template>
  <el-menu-item :index="handlePath(routeChildren.path)" @click="handleLink">
    <div class="icon-box" v-if="routeChildren.meta && routeChildren.meta.icon">
      <remix-icon class="menu-icon" :icon="routeChildren.meta.icon"/>
    </div>
    <span>{{ routeChildren.meta.title }}</span>
    <el-tag v-if="routeChildren.meta && routeChildren.meta.menuBadge"
            type="danger"
            effect="dark">
      {{ routeChildren.meta.menuBadge }}
    </el-tag>
  </el-menu-item>
</template>

<script>
import { util} from "@zhoujianhui/vip-core"

export default {
  name: "MenuItem",
  props: {
    routeChildren: {
      type: Object,
      default() {
        return null;
      }
    },
    item: {
      type: Object,
      default() {
        return null;
      }
    },
    fullPath: {
      type: String,
      default: ""
    }
  },
  methods: {
    handlePath(routePath) {
      if (util.isExternal(routePath)) {
        return routePath;
      }
      if (util.isExternal(this.fullPath)) {
        return this.fullPath;
      }
      return util.buildPath(this.fullPath, routePath);
    },
    handleLink() {
      const routePath = this.routeChildren.path;
      const target = this.routeChildren.meta.target;
      if (target === "_blank") {
        if (util.isExternal(routePath)) {
          window.open(routePath);
        } else if (util.isExternal(this.fullPath)) {
          window.open(this.fullPath);
        } else if (this.$route.path !== util.buildPath(this.fullPath, routePath)) {
          let routeData = this.$router.resolve(
              util.buildPath(this.fullPath, routePath)
          );
          window.open(routeData.href);
        }
      } else {
        if (util.isExternal(routePath)) {
          window.location.href = routePath;
        } else if (util.isExternal(this.fullPath)) {
          window.location.href = this.fullPath;
        } else if (this.$route.path !== util.buildPath(this.fullPath, routePath)) {
          this.$router.push(util.buildPath(this.fullPath, routePath));
        }
      }
    }
  }
}
</script>
