<template>
  <div id="tabs-bar-container" class="tabs-bar-container">
    <el-tabs v-model="tabActive"
             type="card"
             class="tabs-content"
             @tab-click="handleTabClick"
             @tab-remove="handleTabRemove">
      <el-tab-pane v-for="item in visitedRoutes"
                   :key="item.path"
                   :label="item.meta.title"
                   :name="item.path"
                   :closable="!isAffixed(item)"/>
    </el-tabs>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import {eventBus, util} from "@zhoujianhui/vip-core"

export default {
  name: "TabsBar",
  data() {
    return {
      affixedTabs: [],
      tabActive: "",
      routes: this.$router.options.routes
    }
  },
  mounted() {
    eventBus.$on("EVENT_LOGOUT", () => {
      this.$store.dispatch("gta/delAllVisitedRoutes")
    })
  },
  computed: {
    ...mapGetters({
      visitedRoutes: "gta/visitedRoutes"
    })
  },
  watch: {
    $route: {
      handler() {
        this.initTabs();
        this.addTabs();
        this.initActiveTab();
      },
      immediate: true
    }
  },
  methods: {
    async handleTabRemove(tabActive) {
      const view = this.visitedRoutes.find(route => tabActive === route.path);
      const {visitedRoutes} = await this.$store.dispatch("gta/delVisitedRoute", view);
      if (this.isActive(view)) {
        this.toLastTab(visitedRoutes, view);
      }
    },
    handleTabClick(tab) {
      // this.$nextTick(function (){
      //   var myEvent= new Event('resize');
      //   window.dispatchEvent(myEvent)
      // })
      const route = this.visitedRoutes.find((route) => {
        if (tab.name === route.path) return route;
      });
      if (this.$route.path !== route.path) {
        this.$router.push({
          path: route.path,
          query: route.query,
          fullPath: route.fullPath,
        });
      }
    },
    isActive(route) {
      return route.path === this.$route.path;
    },
    isAffixed(tab) {
      return tab.meta && tab.meta.isAffixed;
    },
    filterAffixedTabs(routes, basePath = "/") {
      let tabs = [];
      routes.forEach((route) => {
        if (route.meta && route.meta.isAffixed) {
          const tabPath = util.buildPath(basePath, route.path)
          tabs.push({
            fullPath: tabPath,
            path: tabPath,
            name: route.name,
            meta: {...route.meta},
          });
        }
        if (route.children) {
          const tempTabs = this.filterAffixedTabs(route.children, route.path);
          if (tempTabs.length >= 1) {
            tabs = [...tabs, ...tempTabs];
          }
        }
      });
      return tabs;
    },
    initTabs() {
      this.affixedTabs = this.filterAffixedTabs(this.routes);
      for (const tab of this.affixedTabs) {
        if (tab.name) {
          this.$store.dispatch("gta/addVisitedRoute", tab);
        }
      }
    },
    addTabs() {
      let route = this.$route
      if (route.meta && route.meta.isAffixed === undefined) {
        route.meta.isAffixed = false
      }
      this.$store.dispatch("gta/addVisitedRoute", route);
    },
    initActiveTab() {
      const activeRoute = this.visitedRoutes.find(route => route.path === this.$route.path);
      this.tabActive = activeRoute.path;
    },
    toLastTab(visitedRoutes) {
      const latestView = visitedRoutes.slice(-1)[0];
      const lastViewPath = latestView.path || "/dashboard";
      this.$router.push(lastViewPath);
    },
    // 定位到当前Tab视图
    toThisTab() {
      return this.visitedRoutes.find(visitedRoute => visitedRoute.name === this.$route.name);
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs-bar-container {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  width: calc(100% - 240px);

  :deep(.tabs-content) {
    width: 100%;

    .el-tabs__header {
      border-bottom: 0;
      margin: 0;

      .el-tabs__nav-next, .el-tabs__nav-prev {
        line-height: 34px;
      }

      .el-tabs__nav {
        border: 0;
      }

      .el-tabs__item {
        box-sizing: border-box;
        margin-right: 5px;
        height: 30px;
        line-height: 30px;
        transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
        color: rgb(105, 109, 122);
        border-left: none;

        &.is-active {
          border: none;
          border-radius: 4px;
          background-color: rgb(255, 255, 255,.9);
        }

        &:first-child {
          padding-left: 0;

          &.is-active {
            padding: 0 20px;
          }

        }

      }

    }

  }

}
</style>
