/** * Copyright (C) 1990 2021 南京擎天科技集团
 * user-issue-service.js
 * 创建人:王彬
 * 日期:2022-08-26 17:05
 * 描述: 用户反馈操作
 * 历史:
 */
import axios from "axios";
import {Result, URLSearchParamsBuilder} from "@zhoujianhui/vip-core";

const userIssueService = {
    async addIssue(issueType,issueCategory,description,title) {
        try {
            let params = {type:issueType,category:issueCategory}
            if (description){
                params["description"] = description
            }
            if (title){
                params["title"] = title
            }
            const response = await axios.post("/user-issue", params)
            return Result.success("添加成功！").data(response.data).build();
        } catch (e) {
            return Result.error("添加异常！错误原因：" + e.message).build();
        }
    },
    async getIssueInfo(id) {
        try {
            const response = await axios.get( "/user-issue/find-by-noticeId", {
                params: new URLSearchParamsBuilder()
                    .append("id",id)
                    .build()
            })
            return Result.success("查询成功！").data(response.data).build();
        } catch (e) {
            return Result.error("查询异常！错误原因：" + e.message).build();
        }
    },
}

export default userIssueService;



