<template>
  <div class="portal-layout">
    <router-view/>
    <PortalFooter/>
  </div>
</template>

<script>
import PortalFooter from '../../portal/view/PortalFooter'
import '../style/portal-vab.scss'

export default {
  name: "PortalLayout",
  components: {
    PortalFooter
  },
  beforeRouteLeave(to, from, next) {
    window.scrollTo(0,0)
    next()
  },
  mounted() {
    const html = document.querySelector('html');
    html.classList.add('witseye-portal');
  },
  beforeDestroy(){
    const html = document.querySelector('html');
    html.classList.remove('witseye-portal');
  }
}
</script>

<style lang="scss" scoped>

</style>
