<template>
  <div v-if="showQrCode" class="qr-code">
    <el-row v-if="collapse" class="qr-code-collapse" type="flex" justify="center">
      <div class="qr-code-bg">
        <img src="../images/collapse-code.png" alt=""/>
      </div>
    </el-row>
    <el-row v-else class="qr-code-not-collapse">
      <div class="qr-code-bg">
        <img src="../images/qr-code.png" alt=""/>
        <el-row class="intro">全球获客平台</el-row>
        <el-row class="intro">应用移动端</el-row>
        <el-row class="wexin-download">微信扫一扫立即下载</el-row>
      </div>
    </el-row>
  </div>
</template>

<script>
import { configManager } from '@zhoujianhui/vip-core'
import {mapGetters} from "vuex";

export default {
  name: "QrCode",
  computed: {
    showQrCode: ()=> configManager.getConfig().theme.showQrCode,
    ...mapGetters({
      collapse: "gta/collapse"
    })
  }
}
</script>

<style lang="scss" scoped>
.qr-code {
  margin-top: 20px;
  .qr-code-collapse {
    padding-bottom: 20px;

    .qr-code-bg {
      background: url("../images/collapse-code-bg.png") no-repeat;
      background-size: 100% 100%;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .qr-code-not-collapse {
    padding: 0 0 70px 30px;

    .qr-code-bg {
      background: url("../images/collapse-code-bg.png") no-repeat;
      background-size: 100% 100%;
      padding: 12px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      width: 136px;

      img {
        margin-bottom: 6px;
      }

      .intro {
        font-size: $base-font-size-default;
        color: rgb(34, 34, 34);
        line-height: 1.4;
      }

      .wexin-download {
        font-size: $base-font-size-small;
        color: rgb(105, 109, 122);
        line-height: 1.6;
      }

    }
  }

}
</style>
