<template>
    <component :is="menuComponent"
               v-if="item.meta.isMenu"
               :item="item"
               :full-path="fullPath"
               :route-children="routeChildren">
        <template v-if="item.children && item.children.length">
            <side-bar-item v-for="route in item.children"
                           :key="route.path"
                           :full-path="handlePath(route.path)"
                           :item="route"/>
        </template>
    </component>
</template>

<script>
    import {util} from "@zhoujianhui/vip-core"
    import SubMenu from "./SubMenu"
    import MenuItem from "./MenuItem"

    export default {
        name: "SideBarItem",
        components: {SubMenu, MenuItem},
        props: {
            item: {
                type: Object,
                required: true
            },
            fullPath: {
                type: String,
                default: ""
            }
        },
        data() {
            return {
                routeChildren: []
            }
        },
        computed: {
            menuComponent() {
                if (this.handleChildren(this.item.children, this.item) &&
                    (!this.routeChildren.children || this.routeChildren.notShowChildren)) {
                    return "MenuItem";
                } else {
                    return "SubMenu";
                }
            },
        },
        methods: {
            handleChildren(children = [], parent) {
                if (children === null) children = [];
                const showChildren = children.filter((item) => {
                    if (item.meta.isMenu) {
                        this.routeChildren = item;
                        return true;
                    } else {
                        return false;
                    }
                });
                if (showChildren.length === 1 && parent.meta.parentRouteId === -1) {
                    return true;
                }

                if (showChildren.length === 0) {
                    this.routeChildren = {
                        ...parent,
                        path: "",
                        notShowChildren: true,
                    };
                    return true;
                }
                return false;
            },
            handlePath(routePath) {
                if (util.isExternal(routePath)) {
                    return routePath;
                }
                if (util.isExternal(this.fullPath)) {
                    return this.fullPath;
                }
                return util.buildPath(this.fullPath, routePath);
            }
        }
    }
</script>

<style lang="scss" scoped>
    :deep(.icon-box) {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        max-width: 30px;
        width: 100%;
        height: 100%;

        .vab-fas-icon {
            font-size: $base-font-size-default;
        }

        .vab-remix-icon {
            font-size: $base-font-size-default + 2;
        }

    }

    :deep(.el-tag) {
        float: right;
        height: 16px;
        padding-right: 4px;
        padding-left: 4px;
        margin-top: calc((#{$base-menu-item-height} - 16px) / 2);
        line-height: 16px;
        border: 0;
    }
</style>