<template>
  <el-breadcrumb class="breadcrumb-container" separator=">">
    <el-breadcrumb-item v-for="item in list" :key="item.path">
      <remix-icon v-if="item.meta.icon"
                  class="breadcrumb-icon"
                  :icon="item.meta.icon"/>
      <span style="font-weight: bold; color: #222222;vertical-align: middle">{{ item.meta.title }}</span>
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
export default {
  name: "BreadCrumb",
  data() {
    return {
      list: this.getBreadcrumb(),
    }
  },
  watch: {
    $route() {
      this.list = this.getBreadcrumb()
    }
  },
  methods: {
    getBreadcrumb() {
      return this.$route.matched.filter(
          (item) => item.name && item.meta.title
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb-container {
  height: $base-nav-bar-height;
  font-size: $base-font-size-default;
  line-height: $base-nav-bar-height;

  .breadcrumb-icon {
    margin-right: 3px;
    vertical-align: middle;
    font-size: $base-font-size-small;
  }
  /*.active{*/
  /*  font-weight: bold;*/
  /*  color: #000000;*/
  /*}*/
  :deep(.el-breadcrumb__inner) {
    color: #8e94a3;
  }

}
</style>
