<template>
  <el-submenu ref="subMenu"
              :index="handlePath(item.path)"
              :popper-append-to-body="false">
   <template slot="title">
      <div class="icon-box" v-if="item.meta && item.meta.icon">
        <remix-icon class="menu-icon" :icon="item.meta.icon"/>
      </div>
      <span>{{ item.meta.title }}</span>
    </template>
    <slot></slot>
  </el-submenu>
</template>

<script>
import {util} from "@zhoujianhui/vip-core"

export default {
  name: "SubMenu",
  props: {
    routeChildren: {
      type: Object,
      default() {
        return null;
      }
    },
    item: {
      type: Object,
      default() {
        return null;
      }
    },
    fullPath: {
      type: String,
      default: ""
    }
  },
  methods: {
    handlePath(routePath) {
      if (util.isExternal(routePath)) {
        return routePath;
      }
      if (util.isExternal(this.fullPath)) {
        return this.fullPath;
      }
      return util.buildPath(this.fullPath, routePath);
    }
  }
}
</script>
