<template>
  <div class="logo">
    <div @click="$router.push({path: '/home'})" class="eye-logo" v-if="!collapse"></div>
    <div @click="$router.push({path: '/home'})" class="small-eye-logo" v-else></div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "LogoComponent",
  computed: {
    ...mapGetters({
      collapse: "gta/collapse"
    })
  },
}
</script>

<style lang="scss" scoped>
.logo {
  height: $base-nav-bar-height;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  position: relative;

  &:after {
    content: ' ';
    background-image: linear-gradient( 0deg, rgb(208,216,235) 0%, rgba(162,195,247,0.8) 47%, rgb(208,216,235) 99%, rgb(208,216,235) 100%);
    height: 1px;
    width: calc(100% - 40px);
    position: absolute;
    bottom: 0;
  }

  .eye-logo {
    background: url("../images/wits-eye.png") no-repeat;
    width: 125px;
    height: 32px;
    background-size: 100% 100% !important;
    cursor: pointer
  }

  .small-eye-logo {
    background: url("../images/dark-eye-log.png") no-repeat;
    width: 33px;
    height: 33px;
    background-size: 100% 100% !important;
    cursor: pointer
  }

}
</style>
