<template>
  <div class="top-bar" id="top-bar">
    <div class="top-bar-left" id="top-bar-left">
      <bread-crumb/>
      <div class="top-bar-split-line"></div>
      <tabs-bar/>
    </div>
    <div class="top-bar-right" id="top-bar-right">
      <div class="tool-box">
        <div class="tool-box-item" title="换肤" @click="handleThemeChange">
            <img :src="srcImage" alt=""/>
        </div>
        <div>
          <div class="tool-box-item ring" title="消息提醒" v-if="isAllRead" @click="skipMessageVue">
            <img src="../images/ring.png" alt=""/>
          </div>
          <div class="tool-box-item noring" title="消息提醒" @click="skipMessageVue" v-else>
            <img src="../images/ring.png" alt=""/>
          </div>
        </div>
        <div class="tool-box-item" title="帮助指南" @click="skipHelpVue">
          <img src="../images/question.png" alt=""/>
        </div>

      </div>
      <div @click="skipMyAccount">
        <img class="avatar" :src="avatar" alt=""/>
      </div>
      <div class="username">
        Hello,
        <span>{{ name ? name : phoneNumber }}</span>
      </div>
      <img class="close-icon" title="退出" src="../images/close.png" @click="handleLogoutClick" alt=""/>
    </div>
    <span class="collapse-icon"
          :title="collapse ? '展开' : '收起'"
          :class="collapse ? 'open' : 'collapse'"
          @click="handleCollapse">
    </span>
  </div>
</template>

<script>
import BreadCrumb from './BreadCrumb'
import TabsBar from './TabsBar'
import noticeManager from "@zhoujianhui/vip-notice-element";
import {configManager, eventBus, PaginationManager} from "@zhoujianhui/vip-core";
import {mapGetters} from "vuex";
import noReadMessageService from "../service/no-read-message";

export default {
  name: "TopBar",
  data() {
    return {
      srcImage: require('../images/theme.png'),
      paginationManager: PaginationManager.builder().callback(this.getNoReadMessage).build(),
      tableDate2: [],
      query: {
        isRead: '0',
        client: 'PC',
      },
      isAllRead: Boolean,
    }
  },
  components: {
    BreadCrumb, TabsBar
  },
  computed: {
    ...mapGetters({
      username: "gta-user/username",
      phoneNumber: "gta-user/phoneNumber",
      name: "gta-user/name",
      avatar: "gta-user/avatar",
      collapse: "gta/collapse",
      style: "gta/style"
    })
  },
  mounted() {
    eventBus.$on("updateIsRead", (isAllRead)=>{
      this.isAllRead = isAllRead
    })
  },
  async created() {
    if (this.style === 'dark') {
      document.getElementsByTagName("body")[0].className = `global-trade-analysis-${this.style}`;
      localStorage.setItem("global-trade-analysis", this.style);
    }
    await this.getNoReadMessage()
  },
  methods: {
    skipHelpVue() {
      this.$router.push({
        path: '/use-help/operating-guide',
      })
    },
    skipMyAccount() {
      this.$router.push({
        path: '/user-center/personal-details',
      })
    },
    skipMessageVue() {
      this.$router.push({
        path: '/user-center/message-remind',
      })
    },
    async getNoReadMessage() {
      let result = await noReadMessageService.getNoReadMessage(this.paginationManager.toPageable(), this.query);
      if (result.isSuccess()) {
        this.isAllRead = result.data.content.length > 0;
      } else {
        noticeManager.showMessage(result.msg, "error")
      }
    },
    handleLogoutClick() {
      noticeManager.showConfirmDialog(
          "您确定要退出" + configManager.getConfig().title + "吗?",
          null,
          async () => {
            // 发送登出事件由vip-core处理登出
            eventBus.$emit("EVENT_LOGOUT")
          }
      )
    },
    handleThemeChange() {
      const themeStyle = 'dark';
      if (localStorage.getItem("global-trade-analysis")) {
        document.getElementsByTagName("body")[0].classList.remove(`global-trade-analysis-${themeStyle}`);
        localStorage.removeItem("global-trade-analysis");
        this.srcImage = require('../images/theme.png')
      } else {
        localStorage.setItem("global-trade-analysis", themeStyle);
        document.getElementsByTagName("body")[0].className = `global-trade-analysis-${themeStyle}`;
        this.srcImage = require('../images/dark-theme.png')
      }
    },
    handleCollapse() {
      this.$store.dispatch("gta/changeCollapse");
    }
  }
}
</script>

<style lang="scss" scoped>
.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $base-padding;
  background: url("../images/top-bar.png") no-repeat;
  background-color: #f1f6fd;
  background-size: 100% 100%;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - #{$base-left-menu-width});
  left: $base-left-menu-width;
  z-index: $base-z-index+1;
  //z-index:2001;

  &:after {
    content: ' ';
    background-image: linear-gradient(0deg, rgb(208, 216, 235) 0%, rgba(162, 195, 247, 0.8) 47%, rgb(208, 216, 235) 99%, rgb(208, 216, 235) 100%);
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  .top-bar-left {
    display: flex;
    align-items: center;
    width: calc(100% - 350px);
  }

  .top-bar-split-line {
    background-color: rgb(175, 204, 248);
    width: 1px;
    height: 15px;
    margin: 0 15px;
  }

  .top-bar-right {
    display: flex;
    align-items: center;

    .tool-box {
      display: flex;

      .tool-box-item {
        border-radius: 4px;
        background-color: rgb(255, 255, 255, .8);
        box-shadow: 0px 10px 23.22px 3.78px rgba(235, 235, 235, 0.26);
        width: 30px;
        height: 30px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 20px;

        &.ring {
          position: relative;

          &:after {
            content: " ";
            border-radius: 50%;
            background-color: rgb(255, 68, 51);
            position: absolute;
            width: 8px;
            height: 8px;
            top: 5px;
            right: 5px;
          }

        }

        &.noring {
          position: relative;

          &:after {
            content: " ";
            border-radius: 50%;
            background-color: rgb(255, 68, 51);
            position: absolute;
            width: 8px;
            height: 8px;
            top: 5px;
            right: 5px;
            display: none;
          }

        }

        img {
          position: relative;
        }

      }

    }

    .avatar {
      border-radius: 45px;
      cursor: pointer;
      margin-left: 10px;
      width: 26px;
      height: 26px;
    }

    .close-icon {
      cursor: pointer;
    }

    .username {
      margin: 0 20px 0 5px;
      color: #696d7a;

      span {
        color: #222222;
      }

    }

  }

  .collapse-icon {
    position: absolute;
    left: -25px;
    z-index: $base-z-index + 1;
    top: 48px;
    cursor: pointer;
    background-size: 100% 100%;
    width: 50px;
    height: 51px;

    &:hover {
      opacity: .8;
    }

    &.collapse {
      background: url("../images/collapse.png") no-repeat;
    }

    &.open {
      background: url("../images/open-menu.png") no-repeat;
    }

  }

}
</style>
