<template>
  <el-scrollbar class="side-bar-container" :class="{ 'is-collapse': collapse }">
    <logo/>
    <div class="menu-and-code">
      <el-menu :background-color="variables['menu-background']"
               :text-color="variables['menu-color']"
               :active-text-color="variables['menu-color-active']"
               :default-active="activeMenu"
               :collapse="collapse"
               :collapse-transition="false"
               :default-openeds="defaultOpened"
               :unique-opened="uniqueOpened"
               class="side-menu"
               mode="vertical">
        <template v-for="route in routes">
          <side-bar-item :key="route.path"
                         :full-path="route.path"
                         :item="route"/>
        </template>
      </el-menu>
      <qr-code/>
    </div>
  </el-scrollbar>
</template>
<script>
import {mapGetters} from "vuex";
import {configManager} from "@zhoujianhui/vip-core"
import SideBarItem from "./SideBarItem"
import Logo from "../Logo"
import QrCode from '../QrCode'
import variables from "../../style/export.module.scss"

export default {
  name: "SideBar",
  components: {
    Logo,
    SideBarItem,
    QrCode
  },
  data() {
    return {
      uniqueOpened: configManager.getConfig().theme.uniqueOpened,
      routes: this.$router.options.routes.sort((route1, route2) => {
        return route1.meta.menuIndex - route2.meta.menuIndex
      })
    }
  },
  computed: {
    ...mapGetters({
      collapse: "gta/collapse"
    }),
    defaultOpened() {
      return configManager.getConfig().theme.defaultOpened
    },
    activeMenu() {
      const {meta, path} = this.$route;
      return meta.activeMenu || path;
    },
    variables() {
      return variables
    }
  }
}
</script>
<style lang="scss" scoped>
@mixin el-menu-item {
  height: $base-menu-item-height;
  overflow: hidden;
  line-height: $base-menu-item-height;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;

  &.is-active {
    color: $base-color-white;
    border-radius: 4px;
    background-color: $base-menu-background-active !important;
  }

  .icon-box {
    color: #b1b4c4;
  }



  &.is-active {
    .icon-box {
      color: $base-color-white;
    }

  }

}

.side-bar-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: $base-z-index;
  width: $base-left-menu-width;
  height: 100vh;
  overflow: hidden;
  background: url("../../images/menu-bg.png") no-repeat;
  background-size: 100% 100% !important;
  //transition: width $base-transition-time;

  .menu-and-code {
    min-height: calc(100vh - 66px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &.is-collapse {
    width: $base-left-menu-width-min;
    border-right: 0;

    :deep(.el-menu) {
      transition: width $base-transition-time;

      &.side-menu {
        margin: 20px 0 0 0;
        width: $base-left-menu-width-min - 1px;
      }

      .icon-box {
        max-width: unset;
      }

      .el-submenu.is-active {
        .icon-box {
          border-radius: 4px;
          background-color: rgba(28, 117, 253, .1);
        }
      }

      .el-menu-item.is-active {
        background-color: transparent !important;
        color: #1c75fd !important;

        .icon-box {
          color: #1c75fd;
          border-radius: 4px;
          background-color: rgba(28, 117, 253, .1);
        }
      }

      .el-menu-item, .el-submenu__title {
        &:hover {
          background: unset !important;

          .icon-box {
            border-radius: 4px;
            background-color: rgba(28, 117, 253, .1);
            color: #1c75fd;
          }
        }
      }

      &.el-menu--popup {
        .el-menu-item:hover, .el-menu-item.is-active {
          background: rgba(28, 117, 253, .1) !important;
          color: #1c75fd !important;
        }
      }

      &.el-menu--popup {
        .el-menu-item {
          .icon-box {
            display: none;
          }
        }
      }

    }

  }

  :deep(.el-scrollbar__wrap) {
    overflow-x: hidden;
  }

  :deep(.el-menu) {
    border: 0;

    &.side-menu {
      margin: 20px 30px 0;
    }

    .el-menu-item, .el-submenu__title {
      @include el-menu-item;
    }

    .el-menu-item {
      &:hover {
        color: $base-color-white !important;
        border-radius: 4px;
        background-color: $base-menu-background-active !important;

        .icon-box {
          color: $base-color-white;
        }

      }
    }

    .el-submenu__title {

      &:hover {
        background: unset !important;
      }

      i.el-submenu__icon-arrow {
        top: 32px;

        &:before {
          content: '';
          background: url("../../images/down-arrow.png") no-repeat;
          width: 10px;
          height: 6px;
          background-size: 100% 100%;
          display: block;
        }

      }


    }

    &.el-menu--popup {
      background: $base-color-white !important;
    }

  }

  :deep(.el-submenu) {

    .el-menu-item {
      padding: 0 20px 0 50px !important;
      min-width: auto;
    }

    &.is-active {
      .el-submenu__title {
        color: #222222 !important;
        font-weight: bold;
           .icon-box{
             color: #1c75fd;
           }
        svg {
          color: #1c75fd;
        }

      }
    }

  }

}
</style>
