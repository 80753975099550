<template>
  <div class="wits-eyes-default-layout">
    <side-bar/>

    <div class="wits-eyes-main" :class="collapse ? 'is-collapse-main' : ''">
      <top-bar/>
      <app-main/>
    </div>
  </div>
</template>

<script>
import {eventBus} from '@zhoujianhui/vip-core'
import SideBar from '../components/sidebar'
import TopBar from '../components/TopBar'
import AppMain from '../components/AppMain'
import "../style/web-vab.scss"
import "../style/element-variables.scss"
import { mapGetters } from "vuex";
import {packageManager} from "@vip/global-trade-analysis-ui-module-package"
import routerManger from "@zhoujianhui/vip-core/src/manager/router-manager";

export default {
  name: "DefaultLayout",
  components: {SideBar, TopBar, AppMain},
  async beforeRouteEnter(to, from, next) {
    if (to.meta.packagePermissionResource &&
        !await packageManager.checkCurrentUserPackagePermissions(to.meta.packagePermissionResource)) {
      //登录过来的如无权限则进入/dashboard
      if (from && (from.path=='/login')){
        routerManger.router.push(`/dashboard`)
      } else {
        eventBus.$emit('isPackagePermissionResource',{issueType:to.meta.packagePermissionResource,category:''});
      }
      next(false);
    } else {
      next();
    }
  },
  async beforeRouteUpdate(to, from, next){
    if (to.meta.packagePermissionResource &&
        !await packageManager.checkCurrentUserPackagePermissions(to.meta.packagePermissionResource)) {
      eventBus.$emit('isPackagePermissionResource',{issueType:to.meta.packagePermissionResource,category:''});
      next(false);
    } else {
      next();
    }
  },
  computed: {
    ...mapGetters({
      collapse: "gta/collapse"
    })
  },
  mounted() {
    const html = document.querySelector('html');
    html.classList.add('witseye-system');
  },
  beforeDestroy(){
    const html = document.querySelector('html');
    html.classList.remove('witseye-system');
  }
}
</script>

<style lang="scss" scoped>
.wits-eyes-default-layout {

  .wits-eyes-main {
    position: relative;
    min-height: 100%;
    margin-left: $base-left-menu-width;
    background: #f6f8f9;
    transition: $base-transition;

    &.is-collapse-main {
      margin-left: $base-left-menu-width-min;

      .top-bar {
        left: $base-left-menu-width-min;
        width: calc(100% - #{$base-left-menu-width-min});
      }
    }

  }

}
</style>
